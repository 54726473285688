import React, { useCallback, useMemo } from 'react';
import { changePageTitle } from '@ubeya/shared/hooks/usePageTitle';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import styled, { css } from 'styled-components';
import { ReactComponent as SoonChip } from '@ubeya/shared-web/assets/soon-chip.svg';
import { ModuleIcons } from '@ubeya/shared-web/assets/SidebarIcons';
import { ReactComponent as BaseStarIcon } from '@ubeya/shared-web/assets/is-host-star.svg';
import { FlexCenter, FlexMiddle } from '../Flex';
import { Body } from '../index';

const StarIcon = styled(BaseStarIcon)`
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 0;
  transition: opacity 150ms;
`;

const InlineStarIcon = styled(BaseStarIcon)`
  margin-right: 4px;
`;

const Submenu = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  z-index: 10000;
  min-width: 200px;
`;

const SubmenuContainer = styled.div`
  padding: 8px;
  z-index: 3;
`;

const CircleIcon = styled(FlexCenter)`
  border-radius: 8px;
  cursor: pointer;
  transition: 200ms;
  width: 40px;
  height: 40px;
  margin-bottom: 2px;
  position: relative;
  ${({ $disabled }) => $disabled && 'opacity: 0.7;'}
`;

const SubmenuRow = styled.div`
  text-decoration: none;
  display: flex;
  background-color: ${({ theme }) => theme.colors.surface};
  align-items: center;
  justify-content: flex-start;
  padding: 8px 8px 8px 16px;
  gap: 8px;
  border-radius: 4px;
  cursor: ${({ $disabled }) => ($disabled ? 'normal' : 'pointer')};
  transition: all;
  color: ${({ theme, $isSelected }) => ($isSelected ? theme.colors.dashboard.purple : theme.colors.gray200)};
  :hover {
    background-color: ${({ theme, $disabled }) => !$disabled && theme.colors.primaryLighter};
  }
`;

const BottomBorder = styled.div`
  height: 1px;
  width: 80px;
  margin: 10px 0;
  background-color: #ffffff99;
`;

const PopoverWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const SubmenuText = styled.div`
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
`;

const NewModuleIcon = styled(ModuleIcons.CommonModuleIcons.NewModule)`
  position: absolute;
  top: 3px;
  right: 3px;
`;

const Link = styled(FlexMiddle)`
  text-decoration: none;
  padding: 10px 8px;
  width: 100%;
  position: relative;
  transition: 300ms;
  justify-content: center;
  flex-direction: column;
  ${({ $mainIcon }) => $mainIcon && 'margin-bottom: 28px;'}
  ${() =>
    css`
      > ${CircleIcon} {
        background-color: ${({ theme, $shouldHover, $shouldBeSelected }) =>
          $shouldBeSelected ? theme.colors.primary : $shouldHover ? theme.colors.sidebarHover : 'transparent'};
      }
      > ${StarIcon} {
        opacity: ${({ $shouldHover, $shouldBeSelected }) => ($shouldBeSelected || $shouldHover ? 1 : 0)};
      }
    `}
    &.active {
    > ${CircleIcon} {
      background: ${({ theme }) => theme.colors.primary};
    }
    > ${StarIcon} {
      opacity: 1;
    }
  }

  &:hover {
    ${() =>
      css`
        > ${CircleIcon} {
          background: ${({ theme }) => theme.colors.sidebarHover};
        }
        > ${StarIcon} {
          opacity: 1;
        }
      `}
  }
`;

const strokeIcons = ['/employees', '/statements'];

const PopoverContainer = React.forwardRef((props, ref) => (
  <PopoverWrapper ref={ref} onClick={props.onClick ? props.onClick : () => {}}>
    {props.children}
  </PopoverWrapper>
));

const Label = styled(Body)`
  transition: 200ms;
  font-size: 14rem;
  display: flex;
  align-items: center;
  padding: 8px 8px ${({ hasMenu }) => (hasMenu ? 4 : 8)}px 12px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.gray200};
  border-bottom: 1px solid ${({ theme }) => theme.colors.sidebarMenuBorder};
  font-weight: 600;
  line-height: 20px; /* 142.857% */
`;

export const AppSideBarItem = React.memo(
  ({
    to,
    Icon,
    mainIcon,
    t,
    label,
    isOpen,
    disabled,
    hasBottomBorder,
    LinkRenderer,
    isRtl,
    pathname,
    onHover,
    openPopoverIndex,
    isHost,
    index,
    theme,
    accountName,
    includePath = [],
    isNew,
    secondaryMenu,
    shouldChangePageTitle = true,
    accessory: Accessory
  }) => {
    const onClick = useCallback(() => shouldChangePageTitle && changePageTitle(accountName, label, t), [
      accountName,
      shouldChangePageTitle,
      label,
      t
    ]);
    const filteredSecondaryMenu = (secondaryMenu || []).filter((sm) => !sm.isHidden);
    const hasSubMenu = filteredSecondaryMenu.length > 0;
    const splitPathname = (pathname || '').split('/')?.[2];

    const shouldBeSelected = useMemo(
      () =>
        (filteredSecondaryMenu || [])
          .map((secondaryMenuItem) => secondaryMenuItem.to.split('/:accountId')?.[1])
          .some((subpath) => (subpath || '').includes(splitPathname)) || includePath.includes(splitPathname),
      [filteredSecondaryMenu, includePath, splitPathname]
    );

    const handleCircleIconMouseLeave = useCallback(
      (e) => {
        if (hasSubMenu) {
          //Position where mouse left the container on the X axis
          const ox = e.nativeEvent.offsetX;
          //Position where mouse left the container on the Y axis
          const oy = e.nativeEvent.offsetY;
          if ((isRtl && (ox > 5 || oy < 6)) || (!isRtl && (ox < 24 || oy < 1))) {
            onHover(-1);
          }
        } else {
          onHover(-1);
        }
      },
      [hasSubMenu, isRtl, onHover]
    );

    const handleSubmenuMouseLeave = useCallback(
      (e) => {
        if (hasSubMenu) {
          const ox = e.nativeEvent.offsetX;
          const oy = e.nativeEvent.offsetY;
          if ((!isRtl && (oy < 1 || oy > 31 || ox > 10)) || (isRtl && (ox < 195 || (ox > 195 && oy > 30)))) {
            onHover(-1);
          }
        }
      },
      [hasSubMenu, isRtl, onHover]
    );

    return (
      <Popover
        isOpen={index === openPopoverIndex && !mainIcon}
        positions={['right', 'left']} // preferred positions by priority
        padding={10}
        containerStyle={{
          zIndex: 4,
          top: '6px'
        }}
        align="start"
        onClickOutside={() => onHover(-1)}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="white"
            arrowSize={6}
            arrowStyle={{
              marginTop: -7
            }}
            className="popover-arrow-container"
            arrowClassName="popover-arrow">
            <Submenu onMouseLeave={handleSubmenuMouseLeave}>
              <Label hasMenu={(filteredSecondaryMenu || []).length > 0}>
                {isHost && disabled && <InlineStarIcon />}
                {t(label)}
              </Label>
              {(filteredSecondaryMenu || []).length > 0 && (
                <SubmenuContainer>
                  {(filteredSecondaryMenu || []).map((menuItem) => {
                    const SubmenuIcon = menuItem.icon;
                    const path = menuItem.to.split('/:accountId')[1];
                    return (
                      <SubmenuRow
                        as={menuItem.onClick || menuItem.disabled ? undefined : LinkRenderer}
                        key={menuItem.label + menuItem.to}
                        to={menuItem.to}
                        $disabled={menuItem.disabled}
                        $isHost={isHost && disabled}
                        onClick={menuItem.onClick}
                        $isSelected={pathname.includes(path)}>
                        <SubmenuIcon
                          fill={
                            strokeIcons.includes(path)
                              ? 'none'
                              : pathname.includes(path)
                              ? theme.colors.dashboard.purple
                              : theme.colors.gray200
                          }
                          opacity={menuItem.disabled ? 0.5 : 1}
                          stroke={
                            strokeIcons.includes(path)
                              ? pathname.includes(path)
                                ? theme.colors.dashboard.purple
                                : theme.colors.gray200
                              : 'none'
                          }
                        />
                        <SubmenuText $disabled={menuItem.disabled}>{t(menuItem.label || '')}</SubmenuText>
                        {menuItem.disabled && <SoonChip />}
                      </SubmenuRow>
                    );
                  })}
                </SubmenuContainer>
              )}
            </Submenu>
          </ArrowContainer>
        )}>
        <PopoverContainer>
          <Link
            as={LinkRenderer}
            to={to}
            $shouldHover={index === openPopoverIndex || !!Accessory}
            $shouldBeSelected={shouldBeSelected}
            $isOpen={isOpen}
            $isHost={isHost}
            onClick={onClick}
            $mainIcon={mainIcon}>
            <CircleIcon
              $disabled={disabled}
              onMouseLeave={handleCircleIconMouseLeave}
              onMouseEnter={() => onHover(index)}>
              <Icon />
              {isNew && <NewModuleIcon />}
              {!!Accessory && <Accessory />}
            </CircleIcon>
            {isHost && disabled && <StarIcon />}
          </Link>
          {hasBottomBorder && <BottomBorder />}
        </PopoverContainer>
      </Popover>
    );
  }
);
